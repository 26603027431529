svg {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  fill: #cbcbcb;
}

.GeoMap {
  /* font-family: sans-serif; */
  text-align: center;
}

* :focus {outline:0;}